html {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  /* font-family: 'Roboto', sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
  /* overflow-x: hidden; */
  /* text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

a {
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  color: #1e1e1e;
}

/* @media (min-width: 1200px) {
  .container {
    max-width: 1240px !important;
  }
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #00bbf2;
  font-family: "Lora", serif;
}

.card-block h3 {
  font-size: 18px;
}

.card:hover {
  box-shadow: 0 10px 40px rgb(0 0 0 / 50%);
  transform: scale(1.05);
}

p {
  font-size: 15px;
  font-weight: 400;
  color: #7a7a7a;
  text-align: start;
}

em {
  color: #7a7a7a;
  font-size: 14px;
}

.navbar .nav-item:not(:last-child) {
  margin-right: 20px;
}

.dropdown-toggle::after {
  transition: transform 0.15s linear;
}

.show.dropdown .dropdown-toggle::after {
  transform: translateY(3px);
}

.dropdown-menu {
  margin-top: 0;
}

.logo {
  height: auto;
  width: 170px;
}

.navbar-toogler {
  display: none;
}

a.nav-link {
  font-size: 13px;
  line-height: 20px;
  font-family: "Poppins-SemiBold", sans-serif;
  color: #00bbf2;
  text-transform: capitalize;
}

div#navbarSupportedContent {
  justify-content: end;
}
.header-top #contact-info {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .navbar-toogler {
    display: block;
  }
  .header-top #contact-info div {
    display: block;
  }
  .header-top #contact-info {
    display: block;
  }
  .navbar {
    justify-content: left;
  }
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

/*  */

/* Header Style */
/* navbar fixed */
.navbar-fixed {
  top: 0;
  z-index: 100;
  position: fixed;
  width: 100%;
}

#contact-info i {
  padding: 10px;
  margin: 0 auto;
  font-size: 16px;
  color: white;
  transition: 0.5s;
  -webkit-transition: 0.5s;
}

.header-top {
  background-color: #00bbf2;
  color: white;
  padding: 0 1rem;
}

.header-top a {
  color: white;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #00bbf2;
}

.dropdown-menu {
  /* position: absolute; */
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff !important;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 16%);
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  padding: 18px 0;
}

/* .dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
} */
/* .navbar img {
  height: 70px;
} */

.logo-desk {
  display: block;
}

.logo-mob {
  display: none;
}

header {
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: 100px;
  background-color: #f7f7f7;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

/* header .navbar {
  padding: 25px 0px;
} */

.background-header .navbar {
  padding: 17px 0px;
}

.background-header .navbar-nav a.nav-link {
  color: #1e1e1e !important;
}

.background-header .navbar-nav .nav-link:hover,
.background-header .navbar-nav .active > .nav-link,
.background-header .navbar-nav .nav-link.active,
.background-header .navbar-nav .nav-link.show,
.background-header .navbar-nav .show > .nav-link {
  color: #00bbf2 !important;
}

#navbarResponsive {
  z-index: 999;
}

.navbar-collapse {
  text-align: center;
}

.navbar .navbar-nav .nav-item {
  margin: 0px 15px;
}

.navbar .navbar-nav a.nav-link {
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #1e1e1e;
  transition: all 0.3s;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .active > .nav-link,
.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link.show,
.navbar .navbar-nav .show > .nav-link {
  color: #00bbf2;
}

.navbar .navbar-toggler-icon {
  background-image: none;
}

.navbar .navbar-toggler {
  border-color: #fff;
  background-color: #00bbf2;
  height: 46px;
  outline: none;
  border-radius: 0px;
  position: absolute;
  right: 30px;
  top: 25px;
}

.active-page {
  color: #00bbf2;
}
/* .navbar .navbar-toggler-icon:after {
  content: "\f0c9";
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  font-family: "FontAwesome";
} */

@media (max-width: 992px) {
  .background-header .navbar-toggler {
    top: 15px;
  }

  .navbar:after {
    display: none;
  }

  #navbarResponsive {
    z-index: 99999;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  }

  .navbar .navbar-nav .nav-item {
    border-bottom: 1px solid #eee;
  }

  .navbar .navbar-nav .nav-item:last-child {
    border-bottom: none;
  }

  .navbar .navbar-nav a.nav-link {
    padding: 15px 0px;
    color: #1e1e1e !important;
  }

  .navbar .navbar-nav .nav-link:hover,
  .navbar .navbar-nav .active > .nav-link,
  .navbar .navbar-nav .nav-link.active,
  .navbar .navbar-nav .nav-link.show,
  .navbar .navbar-nav .show > .nav-link {
    color: #00bbf2 !important;
    border-bottom: none !important;
    padding-bottom: 15px;
  }
}

.error-font-color {
  color: #ff0000;
}
a.btn.franchise-btn.btn-light {
  color: #00bbf2;
  font-weight: 700;
}

