/* body {
    padding-top: 20px;
  } */
  
  .card-deck {
    margin: 0 -15px;
    justify-content: space-between;
  }
  
  .card-deck .card {
    margin: 0 0 1rem;
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .card-deck .card {
      -ms-flex: 0 0 48.7%;
      flex: 0 0 48.7%;
    }
  }
  
  /* @media (min-width: 768px) and (max-width: 991.98px) {
    .card-deck .card {
      -ms-flex: 0 0 32%;
      flex: 0 0 32%;
    }
  }
  
  @media (min-width: 992px)
  {
    .card-deck .card {
      -ms-flex: 0 0 24%;
      flex: 0 0 24%;
    }
  }
   */