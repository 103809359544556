/* contect page - Contact Form */
/* .contact-company-banner{
    background-image: url(../images/contactbanner.jpg) !important;
} */
.text-blue {
    color: #00bbf2 !important;
    font-size: 1.75rem;
}
.contact-us .down-contact,
.contact-us #map {
    margin-top: 80px;
}

.contact-us .sidebar-heading h2 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.5px;
    color: #20232e;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 25px;
}

.contact-us .contact-form input {
    width: 100%;
    height: 46px;
    /* border: 1px solid #eee; */
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    color: #7a7a7a;
    outline: none;
    padding: 0px 15px;
    margin-bottom: 30px;
}
fieldset.b-input_field {
    position: relative;
}
.b-input_row-label {
    font-weight: 400;
    font-size: .625rem;
    line-height: 1rem;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0.75rem;
    padding: 0.125rem 0.25rem;
    background: #fff;
    transform: translateY(-50%);
    font-size: .6875rem;
}
.b-quantity-select {
    width: 100%;
    height: 2.75rem;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: .75rem;
    padding: 0 1.875rem 0 1rem;
    box-sizing: border-box;
    border: 1px solid #d0d0d0;
    color: #1d1d1d;
    border-radius: 0.25rem;
    line-height: 2.75rem;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: #fff;
}

.contact-us .contact-form textarea {
    width: 100%;
    height: 46px;
    /* border: 1px solid #eee; */
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    color: #7a7a7a;
    outline: none;
    padding: 10px 15px;
    margin-bottom: 30px;
    height: 180px;
    max-height: 220px;
    max-width: 100%;
    min-width: 160px;
}

.contact-us .contact-form input::placeholder,
.contact-us .contact-form textarea::placeholder {
    color: #aaa;
}

.contact-us .contact-form button {
    display: inline-block;
    background-color: #00bbf2;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 20px;
    text-transform: uppercase;
    transition: all 0.3s;
    border: none;
    outline: none;
    border-radius: 50px;
    box-shadow: grey;
}

.contact-us .contact-form .main-button:hover {
    background-color: #00bbf2;
    box-shadow: 0 8px 16px 0 #7a7a7a;
    transform: scale(1.05);
}

.contact-us .contact-information {
    margin-left: 30px;
    box-shadow: 6px 5px 18px rgb(0 0 0 / 20%);
    padding: 30px 40px;
}

.contact-us .contact-information ul li {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}

.contact-us .contact-information ul li:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
}

.contact-us .contact-information ul li {
    list-style-type: none;
}
.contact-us .contact-information ul li a {
    font-size: 19px;
    font-weight: 600;
    color: #00bbf2;
    line-height: 30px;
    text-align: start;
    display: flex;
}
.contact-us .contact-information ul li span {
    display: block;
    font-size: 14px;
    color: #aaa;
    margin-top: 8px;
}

@media (max-width: 992px) {
    .contact-us .contact-information {
        margin-left: 0px;
        margin-top: 60px;
    }
}

img.wp-smiley,
img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}

/*test  */

body {
    background: linear-gradient(264deg, rgba(23, 23, 23, 1) 0%, rgba(33, 33, 33, 1) 49%, rgba(23, 23, 23, 1) 100%);
    font-family: "Open Sans", sans-serif;
    margin: 0;
}
#container {
    margin: auto;
}

.header h1 {
    text-align: center;
    background: -webkit-linear-gradient(#e3ca66, #b48e47);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 4.5em;
    font-family: "Parisienne", cursive;
    margin-bottom: 15px;
}
.header p {
    text-align: center;
    background: -webkit-linear-gradient(#e3ca66, #b48e47);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 20px;
}

.grid-item {
    display: inline-block;
    height: 259px;

    overflow: hidden;
    text-align: center;
    width: 400px;
}
.grid-item img {
    width: 100%;
    height: auto;
    /* filter: url(filters.svg#grayscale); */
    filter: gray;
    -webkit-filter: grayscale(1);
    transition: 0.9s;
}
.grid-item img:hover {
    filter: none;
}

.isotope-pager .pager {
    display: inline-block;
}
.filters {
    width: 100%;
    text-align: center;
    padding-bottom: 35px;
    padding-top: 20px;
}

.filters ul {
    list-style: none;
    padding: 20px 10px;
    box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.4);
    margin: 0 40px;
    border-radius: 30px;
    background-color: #232323;
}

.filters li {
    display: inline-block;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}
.filters li a {
    color: #b6b6b6;
    text-decoration: none;
}
.filters li a:hover {
    color: #fff;
}

.filters li:hover {
    box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

.filters li.active a {
    color: #e3ca66;
    border: 1px solid #e3ca66;
    border-radius: 15px;
    box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.4);
    padding: 10px 25px;
}

.isotope-pager a {
    margin: 6px 1px;
    display: inline-block;
    width: 50px;
    /* height: 60px; */
    line-height: 31px;
    color: #b6b6b6;
    font-weight: 600;
    transition: 0.3s;
    /* position: relative; */
    /* padding-right: 26px; */
    box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    background-color: #232323;
    border: 0.5px solid #e3ca66;
    text-decoration: none;
}

.isotope-pager a:hover {
    color: #fff;
    border: 0.5px solid #ffeeac;
    box-shadow: -1px 1px 10px 3px rgba(0, 0, 0, 0.1);
}

.isotope-pager .active {
    color: #e3ca66 !important;
    box-shadow: -1px 1px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    background-color: #161616;
    border: 1px dotted #e3ca66;
}

.isotope-pager .prev {
    border-radius: 30px 0 0 30px;
}

.isotope-pager .next {
    border-radius: 0 30px 30px 0;
}

.isotope-pager a::before,
.isotope-pager a:after {
    content: "";
    position: absolute;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
}
.isotope-pager .num::before,
.next::before {
    border-right: 20px solid #2c3e50;
    right: 100%;
}

.isotope-pager .num:after,
.prev:after {
    border-right: 20px solid #f1f1f1;
    right: 0;
}

.overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
}

.grid-item:hover .overlay {
    opacity: 1;
}

.AICRI ul,
.AICRI li,
.ProteconProduct ul,
.ProteconProduct li,
.DheyaCareerMentors ul,
.DheyaCareerMentors li,
.TIMTS ul,
.TIMTS li,
.Smart-School ul,
.Smart-School li {
    text-align: justify;
    list-style-type: initial;
}
.CareerFounder ul,
.CareerFounder li{
    text-align: left;
    list-style-type: initial;
    line-height: 1.8;
}

.TIMTS .Embedded-system-details {
    text-align: justify;
}

.TIMTS .Embedded-system-details h3,
.smartschool-details,
.smartschool-details h4 {
    color: #161616;
    font-size: 1.5rem;
}

.smartschool-details h3 ,.smartschool-details h4 {
    text-align: justify;
}
.smartschool-details ul {
    font-size: 15px;
}

/*-- footer --*/
.footer {
    padding: 0.1em 0;
    background: linear-gradient(180deg, rgba(227, 202, 102, 1) 0%, rgba(180, 142, 71, 1) 100%);
    text-align: center;
    margin-top: 20px;
}
.footer p {
    color: #fff;
    letter-spacing: 1px;
}
.footer p a {
    color: #fff;
}
.footer p a:hover {
    color: #fff;
    text-decoration: none;
}
/*-- footer --*/


ul .method-detalis{
    color: #161616;
    font-size: 1.5rem;
    text-align: justify;
}

.stems_study_img {
    width: 50%;
    margin: auto;
}