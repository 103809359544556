/* section.section.policy {
    margin-top: 117px;
} */
.policy h1 {
    font-size: 30px;
    color: #00bbf2;
    line-height: 44px;
    margin: 0 auto;
    display: block;
    line-height: 40px;
    margin-bottom: 30px;
}
.policy h3,
h4 {
    color: #00bbf2;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    font-weight: 600;
    /* font-family: "poppins-semibold", sans-serif; */
}
.policy p,
h5 {
    font-size: 16px;
    line-height: 20px;
    color: #888;
    text-align: justify;
}

.CancellationandRefundPolicy ul, .policy ul {
    list-style: disc;
}