body {
    background: white;
}
.form-style input {
    border: 0;
    height: 50px;
    border-radius: 0;
    border-bottom: 1px solid #ebebeb;
}
.form-style input:focus {
    border-bottom: 1px solid #007bff;
    box-shadow: none;
    outline: 0;
    background-color: #ebebeb;
}
.sideline {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ccc;
}
button {
    height: 50px;
}
.sideline:before,
.sideline:after {
    content: "";
    border-top: 1px solid #ebebeb;
    margin: 0 20px 0 0;
    flex: 1 0 20px;
}

.sideline:after {
    margin: 0 0 0 20px;
}
/* .card {
    background-image: url(../../../images/common/login2.jpg);
} */

.card-bg {
    content: "";
    /* background: url(../../../images/common/footerbg6.jpg) no-repeat center; */
    background-size: cover;
}

.password-control {
    position: absolute;
    top: 11px;
    right: 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../../images/common/view.svg) 0 0 no-repeat;
    transition: 0.5s;
}

.view {
    background: url(../../../images/common/no-view.svg) 0 0 no-repeat;
    transition: 0.5s;
}

.password-input {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}
.blue-button {
    background-color: #00bbf2;
    color: white;
}
.blue-button:hover,
.blue-button:active,
.blue-button:focus {
    background-color: #a9cf46;
}
.blue-colour {
    color: #00bbf2;
}

.shape1,
.shape2,
.shape3,
.shape4 {
    background: rgb(49, 49, 49);
    opacity: 10%;
    transition: all 0.1s ease-in-out;
}

.shape1 {
    position: absolute;
    left: 85%;
    top: 70%;
    height: 200px;
    width: 200px;
    border-radius: 200px;
}

.shape2 {
    position: absolute;
    left: -20%;
    top: -5%;
    height: 200px;
    width: 200px;
    border-radius: 200px;
}

.shape3 {
    position: absolute;
    left: 82%;
    opacity: 5%;
    top: 10%;
    height: 100px;
    width: 100px;
    border-radius: 100px;
}

.shape4 {
    position: absolute;
    left: -5%;
    opacity: 5%;
    top: 70%;
    height: 200px;
    width: 200px;
    border-radius: 200px;
}
@media (max-width: 768px) {
    .shape1,
    .shape2,
    .shape3,
    .shape4 {
       display: none;
    }
  }

/* .top-block1 {
    position: absolute;
    background: rgba(255, 87, 50, 0.9);
    width: 200%;
    height: 300px;
    left: -250px;
    top: -190px;
    transform: rotate(-18deg);
}
.top-block2 {
    position: absolute;
    background: rgba(255, 87, 50, 0.4);
    width: 200%;
    height: 300px;
    top: -80px;
    transform: translate(-230px, -180px) rotate(18deg);
} */
