.main-banner{
    height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    object-fit: cover;
}
@media only screen and (max-width: 768px) {
    .main-banner{
        height: 200px;
    }
    .breadcrumb ul > li {
        font-size: 16px;
    }
  }
.about-banner {
    height: 550px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/istockphoto-1183910040-170667a.jpg");
}

.contact-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/contact1.jpg");
}
.aicra-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/aicra-banner.png");
}
.CareerFounder-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/careers-banner.jpg");
}
.smartschool-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/Illustration-EMF-Blog-Illustration-1-1000x700-1.png");
}
.Getsetgo-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/home/GetSetGo.png");
}
.STEM-Education-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/smart-edu.jpg");
}
.DheyaCareerMentors-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/dheya1.jpg");
}
.ProteconProduct-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/Protecon.jpg");
}
.TIMTS-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/home/timts.jpg");
}
.gallery-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/gallery.jpg");
}
.Achievements-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/awards-banner.jpg");
}
.winwhitelable-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/Whitelabel-App-banner.jpg");
    background-size: contain;
}
/* .Blog-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/blog-banner.jpg");
} */
.BlogSingle-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/blog.jpg");
}
.UpcomingEvents-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/upcommingevents.jpg");
}
.PastEvents-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/Past-Events.png");
}
.Counceling-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/Counceling.jpg");
}
.cfbanner{
    width: 100%;
}
.licancebanner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../images/page/banner/subscription.jpg");
}

/* breadcrums */
.breadcrumb {
    position: relative;
    top: -16px;
    text-align: center;
    margin-bottom: 0;
    justify-content: center;
}
.breadcrumb {
    background: transparent;
    border-radius: 0;
    padding: 0;
}
.breadcrumb > ul {
    background: rgba(0, 187, 242, 0.9) none repeat scroll 0 0;
    display: inline-block;
    /* padding: 0 14px; */
    margin-bottom: 0;
    transform: skewx(-15deg);
}
.breadcrumb ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.breadcrumb ul > li {
    display: inline-block;
    font-size: 20px;
    /* margin: 0 10px; */
    text-transform: uppercase;
    transform: skewx(15deg);
}
.breadcrumb ul > li:first-child > a {
    margin-left: 10px;
}
.breadcrumb ul > li > a {
    color: #fff;
    font-weight: 600;
    padding: 0 5px;
    letter-spacing: 1px;
}
.breadcrumb i {
    font-size: 15px;
    margin-top: 8px;
}
.breadcrumb ul > li:after {
    color: #fff;
    content: "/";
    font-size: 23px;
    margin-left: 0px;
    margin-top: -4px;
    /* position: absolute; */
    padding: 0 11px 0 14px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.6;
}

.breadcrumb ul > li:last-child:after {
    border-right: 0;
    content: "";
    padding: 0;
}
.breadcrumb ul > li:last-child > a {
    color: #f2f2f2;
    margin-right: 10px;
    letter-spacing: 0.5px;
}
.breadcrumb ul > li > a {
    color: #fff;
    font-weight: 500;
    padding: 0 5px;
    letter-spacing: 1px;
}

@media only screen and (max-width: 768px) {
    .winwhitelable .breadcrumb ul>li>a {
        color: #fff;
        font-weight: 500;
        font-size: 13px;
        padding: 0 5px;
        letter-spacing: 1px;
    }
  }
