img.about-us-img {
  height: auto;
  width: 100%;
}

.agenda,
.methods {
  background-image: -webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
  padding: 50px 0;
  margin: 10px 0;
}

.agenda h3,
.agenda li,
.methods h3,
.methods li,
.methods p {
  color: #fff;
}

.methods p {
  font-size: 15px;
  font-weight: bold;
}

.values .card {
  border: 0;
  padding: 160px 20px 20px 20px;
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.values .Mission {
  background-image: url(../../../images/page/aboutus/mission.jpg);
}

.values .Vision {
  background-image: url(../../../images/page/aboutus/vision.jpg);
}

.values .card-body {
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  transition: ease-in-out 0.4s;
  border-radius: 5px;
}

.values .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}

.values .card-title a {
  color: #473d3a;
}

.values .card-text {
  color: #4b4949;
}

.values .read-more a {
  color: #656262;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: 0.4s;
}

.values .read-more a:hover {
  text-decoration: underline;
}

.values .card:hover .card-body {
  background-image: -webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
}

.values .card:hover .read-more a,
.values .card:hover .card-title,
.values .card:hover .card-title a,
.values .card:hover .card-text {
  color: #fff;
}

.parthiv-img {
  background-image: url(../../../images/page/aboutus/parthiv.png);
  height: 140px;
  width: 150px;
  display: block;
}

.sonal-img {
  background-image: url(../../../images/page/aboutus/sonal.png);
  height: 140px;
  width: 150px;
  display: block;
}

@media (min-width: 1200px) {
  .About-module_personDee-bUUve {
    grid-column-start: 2;
    grid-row-start: 4;
  }
}

.About-module_person-oVuIt {
  display: grid;
  grid-template-columns: minmax(0, 0.8fr) minmax(auto, 2fr);
  align-content: center;
  align-items: center;
  /* background-image: -webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%); */
  color: #000;
  padding: 2rem;
  margin: 0 0 2rem;
}

@media (min-width: 770px) {
  .About-module_person-oVuIt img {
    max-width: 120%;
    width: 175px;
  }
}
@media only screen and (max-width: 768px) {
  .order-change {
    display: flex;
    flex-flow: column-reverse;
  }
}

.About-module_person-oVuIt .About-module_bio-zRXvc {
  grid-column: 1/3;
  margin-top: 1rem;
}

.About-module_person-oVuIt p {
  margin: 0.5rem 0;
}

.About-module_person-oVuIt img {
  display: block;
  border: 5px solid var(--cp-color-14);
  transition: 0.2s;
  width: 130px;
  min-width: 100px;
}

ul.method-detalis,
p .method-detalis {
  text-align: justify;
  list-style: disc;
  color: #7a7a7a;
}

.productlisting .productlisting-details {
  text-align: left;
}

.productlisting .badge {
  color: #000;
  padding: 0;
  white-space: break-spaces !important;
  display: block;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #2196f3;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.linkcard {
  position: absolute;
  top: 5px;
  right: 3px;
  display: flex;
}

/* toggle */
.productlisting input,
.couponlisting input {
  display: block;
  opacity: 0;
}

.productlisting label,
.couponlisting label {
  position: relative;
  width: 42px;
  height: 21px;
  display: inline-block;
  background: red;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.productlisting label:after,
.couponlisting label:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 1px 3px 6px #666666;
}

.productlisting input:checked + label,
.couponlisting input:checked + label {
  background: #009900;
}

.productlisting input:checked + label:after,
.couponlisting input:checked + label:after {
  left: auto;
  right: 2px;
}

/*  */
.productlisting .cours-more-info {
  display: flex;
  margin: 0;
  text-align: center;
}
.productlisting .course-detail-bx .cours-more-info .price,
.cours-more-info .review {
  padding: 8px 20px;
}

.productlisting .review {
  border-right: 1px solid #d5d5d5;
}

.order-change.d-flex {
  border-top: 1px solid #eae5e5;
}

.item {
  width: 200px;
  height: 200px;
  margin: 50px auto;
  padding-top: 75px;
  background: #ccc;
  text-align: center;
  color: #fff;
  font-size: 3em;
}

.main-title-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px 30px;
}
.cbse-img {
  align-self: center;
}

