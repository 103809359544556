/*----------------genealogy-scroll----------*/

.genealogy-scroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}
.genealogy-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e4e4e4;
}
.genealogy-scroll::-webkit-scrollbar-thumb {
    background: #212121;
    border-radius: 10px;
    transition: 0.5s;
}
.genealogy-scroll::-webkit-scrollbar-thumb:hover {
    background: #d5b14c;
    transition: 0.5s;
}

/*----------------genealogy-tree----------*/
.genealogy-body {
    white-space: nowrap;
    overflow-y: hidden;
    padding: 50px;
    min-height: 500px;
    padding-top: 10px;
    text-align: center;
}
.genealogy-tree {
    display: inline-block;
}
.genealogy-tree ul {
    padding-top: 20px;
    position: relative;
    /* padding-left: 0px;
    display: flex;
    justify-content: center; */
}
.genealogy-tree li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
}
.genealogy-tree li::before,
.genealogy-tree li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 2px solid #ccc;
    width: 50%;
    height: 18px;
}
.genealogy-tree li::after {
    right: auto;
    left: 50%;
    border-left: 2px solid #ccc;
}
.genealogy-tree li:only-child::after,
.genealogy-tree li:only-child::before {
    display: none;
}
.genealogy-tree li:only-child {
    padding-top: 0;
}
.genealogy-tree li:first-child::before,
.genealogy-tree li:last-child::after {
    border: 0 none;
}
.genealogy-tree li:last-child::before {
    border-right: 2px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
}
.genealogy-tree li:first-child::after {
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
}
.genealogy-tree ul ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 2px solid #ccc;
    width: 0;
    height: 20px;
}
.genealogy-tree li a {
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.genealogy-tree li a:hover + ul li::after,
.genealogy-tree li a:hover + ul li::before,
.genealogy-tree li a:hover + ul::before,
.genealogy-tree li a:hover + ul ul::before {
    border-color: #fbba00;
}

/*--------------memeber-card-design----------*/
.member-view-box {
    padding: 0px 20px;
    text-align: center;
    border-radius: 4px;
    position: relative;
}
.member-image {
    width: 60px;
    position: relative;
}
.member-image img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    background-color: #fff;
    z-index: 1;
}

.UserDetails a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: none !important;
}
.UserDetails h3 {
    font-size: 20px !important;
    margin-top: 5px;
}

/*  */

/*Now the CSS*/
.tree {
    min-width: 600px;
    min-height: 550px;
    overflow: hidden !important;
}

* {
    margin: 0;
    padding: 0;
}

.tree ul {
    padding-top: 20px;
    position: relative;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

.tree li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #ccc;
    width: 50%;
    height: 20px;
}
.tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
    display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
    padding-top: 0;
}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
    border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
    border-right: 1px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
}

.tree li a {
    border: 1px solid #ccc;
    padding: 5px 10px !important;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px !important;
    display: inline-block;

    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    background: none !important;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover + ul li a {
    background: #c8e4f8;
    color: #000;
    border: 1px solid #94a0b4;
}
/*Connector styles on hover*/
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
    border-color: #94a0b4;
}


/* temp css for 2nd tree */
/*Thats all. I hope you enjoyed it.
Thanks :)*/

/*!
 * @copyright &copy; Kartik Visweswaran, Krajee.com, 2014 - 2018
 * @version 1.4.5
 *
 * Bootstrap Popover Extended - Popover with modal behavior, styling enhancements and more.
 *
 * For more JQuery/Bootstrap plugins and demos visit http://plugins.krajee.com
 * For more Yii related demos visit http://demos.krajee.com
 */
/* Hide popover markup by default */
.popover-x {
    display: none;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.popover-x .popover-title {
    border-radius: 5px 5px 0 0;
}

/* Popover & arrow styles for BS4 */
.popover-x.top {
    margin-top: -10px;
}

.popover-x.right {
    margin-left: 10px;
}

.popover-x.bottom {
    margin-top: 10px;
}

.popover-x.left {
    margin-left: -10px;
}

.popover-x > .arrow,
.popover-x > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.popover-x > .arrow {
    border-width: 11px;
}

.popover-x > .arrow:after {
    content: "";
    border-width: 10px;
}

.popover-x.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;
}

.popover-x.top > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0;
}

.popover-x.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-width: 0;
}

.popover-x.right > .arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #fff;
    border-left-width: 0;
}

.popover-x.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.popover-x.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff;
}

.popover-x.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.popover-x.left > .arrow:after {
    right: 1px;
    bottom: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #fff;
}

/* Sizes */
.popover-lg {
    min-width: 480px;
}

.popover-md {
    min-width: 350px;
}

.popover-x .close {
    line-height: 20px;
    outline: none;
}

.popover-x.top-right > .arrow,
.popover-x.bottom-right > .arrow {
    left: 90%;
}

.popover-x.top-left > .arrow,
.popover-x.bottom-left > .arrow {
    left: 10%;
}

.popover-x.left-top > .arrow,
.popover-x.right-top > .arrow {
    top: 10%;
}

.popover-x.left-bottom > .arrow,
.popover-x.right-bottom > .arrow {
    top: 90%;
}

.popover-default.bottom > .arrow:after {
    border-bottom-color: #f7f7f7;
}

.popover-primary.bottom > .arrow:after {
    border-bottom-color: #cce5ff;
}

.popover-success.bottom > .arrow:after {
    border-bottom-color: #dff0d8;
}

.popover-danger.bottom > .arrow:after {
    border-bottom-color: #f2dede;
}

.popover-warning.bottom > .arrow:after {
    border-bottom-color: #fcf8e3;
}

.popover-info.bottom > .arrow:after {
    border-bottom-color: #d9edf7;
}

.popover-default.left-top > .arrow:after {
    border-left-color: #f7f7f7;
}

.popover-default.right-top > .arrow:after {
    border-right-color: #f7f7f7;
}

.popover-primary.left-top > .arrow:after {
    border-left-color: #cce5ff;
}

.popover-primary.right-top > .arrow:after {
    border-right-color: #cce5ff;
}

.popover-success.left-top > .arrow:after {
    border-left-color: #dff0d8;
}

.popover-success.right-top > .arrow:after {
    border-right-color: #dff0d8;
}

.popover-danger.left-top > .arrow:after {
    border-left-color: #f2dede;
}

.popover-danger.right-top > .arrow:after {
    border-right-color: #f2dede;
}

.popover-warning.left-top > .arrow:after {
    border-left-color: #fcf8e3;
}

.popover-warning.right-top > .arrow:after {
    border-right-color: #fcf8e3;
}

.popover-info.left-top > .arrow:after {
    border-left-color: #d1ecf1;
}

.popover-info.right-top > .arrow:after {
    border-right-color: #d1ecf1;
}

.popover-default > .popover-title {
    color: #333;
    background-color: #f7f7f7;
}

.popover-primary > .popover-title {
    color: #004085;
    background-color: #cce5ff;
    border-bottom: 1px solid #b8daff;
}

.popover-success > .popover-title {
    color: #3c763d;
    background-color: #dff0d8;
    border-bottom: 1px solid #d6e9c6;
}

.popover-info > .popover-title {
    color: #0c5460;
    background-color: #d1ecf1;
    border-bottom: 1px solid #bee5eb;
}

.popover-warning > .popover-title {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-bottom: 1px solid #faebcc;
}

.popover-danger > .popover-title {
    color: #a94442;
    background-color: #f2dede;
    border-bottom: 1px solid #ebccd1;
}

.popover-footer {
    padding: 4px;
    background-color: #fbfbfb;
    text-align: right;
    border-top: 1px solid #ebebeb;
    border-radius: 0 0 5px 5px;
}

.popover-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 4px;
}

.popover-footer .btn-group .btn + .btn {
    margin-left: -1px;
}

.popover-footer .btn-block + .btn-block {
    margin-left: 0;
}

.popover-x.has-footer.top > .arrow:after,
.has-footer.popover-x.top > .arrow:after {
    border-top-color: #fbfbfb;
}

.popover-x.has-footer.left-bottom > .arrow:after {
    border-top-color: transparent;
    border-left-color: #fbfbfb;
}

.popover-x.has-footer.right-bottom > .arrow:after {
    border-top-color: transparent;
    border-right-color: #fbfbfb;
}

.popover-loading {
    padding: 30px;
    /* background: url('../img/loading.gif') center center; */
}

.popover-x-body.modal-open {
    overflow-y: auto;
}

/* popover must be behind bootstrap navbar when scrolled */
.popover-x-body .navbar {
    z-index: 1051;
}

/* hide modal backdrop */
.popover-x-body .modal-backdrop {
    opacity: 1;
    z-index: 1;
    display: none;
}
