.container {
    max-width: 1402px;
    margin: auto;
    padding: 15px;
    text-align: center;
}
p {
    font-size: 18px;
}
.home-banner {
    position: relative;
    text-align: center;
    color: white;
    background-size: cover;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    width: 100%;
}
.first-slider-home img {
    opacity: 0.8;
    background-color: black;
}
.home-banner img {
    height: 300px;
    width: 100%;
}
.home-slider .slick-prev {
    left: 18px;
    z-index: 1;
}
.home-slider .slick-next {
    right: 27px;
    z-index: 1;
}
.home-slider .slick-next:before,
.home-slider .slick-prev:before {
    color: #00bbf2;
}
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
}
.hero-text button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 10px 25px;
    color: black;
    background-color: #ddd;
    text-align: center;
    cursor: pointer;
}

.hero-text button:hover {
    background-color: #555;
    color: white;
}
.card-img-top.banner img {
    width: 100%;
    height: 350px;
}
.home-banner .slick-list {
    height: 300px;
}
ul.slick-dots {
    margin: -4px auto;
}
@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
    .home-banner {
        height: 600px;
    }
    .home-banner img {
        width: auto;
        height: 100%;
        margin: auto;
    }
    .home-banner .slick-list {
        height: 600px;
    }
}
.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-image: -webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
    border-radius: 0.3rem;
    color: white;
}
.about-us h3 {
    font-family: "Lora", serif;
    font-weight: bold;
    color: white;
}
.about-us span {
    font-weight: bold;
}
.about-us p {
    margin: 10px 0px;
    font-size: 17px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}
.franchise-section,
.download-from {
    background-image: -webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
}
.franchise-section .info {
    padding-left: 15px;
}
.franchise-section .franchise-btn,
.download-from .download-btn {
    padding: 10px 15px;
    color: #00bbf2;
    font-weight: 600;
}
.testimonial {
    position: relative;
    background-image: url(../../../images/page/home/section_bg.jpg);
    background-size: cover;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 50%;
}
.testimonial .name-client,
.testimonial .description p,
.download-from h3 {
    color: white;
    padding: 20px;
}
.testimonial .slick-list {
    height: auto;
}
@media only screen and (max-width: 768px) {
    .home-banner button {
        padding: 0;
        margin: 5px;
    }
    .home-banner .centered {
        width: 85%;
    }
}
  
/* .Model1{
    width: 5060px;
} */
.justify-content-end.border-bottom-0.modal-header {
    height: 0;
}
.modal-title {
    font-size: medium;
}
.modal-dialog button.close {
    background: #00bbf2;
    border: none;
    height: 25px;
    width: 30px;
    padding: 2px;
    border-radius: 50%;
    font-size: 23px;
    padding-bottom: 35px;
    margin-top: 19px;
}

.line {
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    margin-top: -14px;
}
.line > span {
    display: inline-block;
    width: 170px;
    height: 1px;
    background-color: greenyellow;
    color: #fff;
}
.line > span::before {
    background: #ffffff;
}
.line > span::before {
    bottom: -4px;
    color: #003a6a;
    font-size: 14px;
    height: 17px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: 39px;
    z-index: 9;
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
    content: "\f19d";
}


/* ==========================================================================
    App Features Section Style
   ========================================================================== */
   /* #app-features {
    background-image: -webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
  } */
  
  /* #app-features .content-left {
    position: relative;
  }
  
  #app-features .content-left span {
    float: right;
    margin-left: 25px;
  }
  
  #app-features .content-right {
    position: relative;
  }
  
  #app-features .content-right span {
    float: left;
    margin-right: 25px;
  }
  
  #app-features .box-item {
    margin: 30px 0;
  }
  
  #app-features .box-item .icon {
    text-align: center;
    margin: 0 15px;
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    text-align: center;
    position: relative;
    background-image: -moz-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
    background-image: -webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
    background-image: -ms-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
    z-index: 1;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  
  #app-features .box-item .icon i {
    color: #fff;
    font-size: 24px;
    line-height: 60px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  
  #app-features .box-item .text h4 {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 15px;
    line-height: 22px;
  }
  
  #app-features .box-item .text p {
    font-size: 14px;
    line-height: 26px;
  }
  
  #app-features .content-left .text {
    margin-right: 90px;
  }
  
  #app-features .content-right .text {
    margin-left: 90px;
  }
  
  #app-features .show-box {
    text-align: center;
    margin: 15px 0;
  } */
  .section-header .section-title {
    font-size: 35px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-color: #2dfbff;
    background-image: -webkit-linear-gradient(bottom right, #3c96ff, #2dfbff);
    background-image: -o-linear-gradient(bottom right, #3c96ff, #2dfbff);
    background-image: linear-gradient(to top left, #3c96ff, #2dfbff);
    position: relative;
}
.section-header .section-title.white {
    font-size: 35px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-color: #2dfbff;
    background-image: -webkit-linear-gradient(bottom right, #fff, #d5f4f4);
    background-image: -o-linear-gradient(bottom right, #fff, #d5f4f4);
    background-image: linear-gradient(to top left, #fff, #d5f4f4);
    position: relative;
}
/* .section-title:before {
    position: absolute;
    left: 0;
    bottom: -17px;
    width: 121px;
    height: 4px;
    content: "";
    left: 50%;
    margin-left: -53px;
    background-color: #dfdfdf;
} */
  .intro-img img {
    height: 663px;
}

.app-home{
    align-self: center;
}
.app-home p.main-app-headig {
    color: #004AAD;
    font-weight: bold;
    font-size: 39px;
    text-align: center;
}
.app-home p.red-text {
    color: #FF1616;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
}
.app-home span.lightorange-text {
    color: #D86924;
}
.app-home span.blue-text {
    color: #104080;
}
.app-home p.blue-stander-text {
    color: #004AAD;
    font-size: 54px;
    font-weight: 900;
    text-align: left;
}
.app-home span.Free-text {
    color: #FF1616;   
    margin-left: -176px;
}
.app-home p.black-dw-app-label {
    font-size: 40px;
    font-weight: 800;
    color: #000;
    text-align: center;
}
.app-home p.black-dw-app-label span.lightorange-text {
    margin-left: -8px;
}

@font-face{font-family:"fontfutura";src:url("https://fonts.googleapis.com/css?family=Open+Sans") format("ttf");font-weight:normal;font-style:normal;}
a.btn-google{color:#fff}
.btn{padding:10px 16px;margin:5px;font-size:18px;line-height:1.3333333;border-radius:6px;text-align:center;white-space:nowrap;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border:1px solid transparent;font-weight:500;text-decoration:none;display:inline-block}
.btn:active:focus,.btn:focus{outline:0}
.btn:focus,.btn:hover{color:#333;text-decoration:none;outline:0}
.btn:active{outline:0;-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,.125);box-shadow:inset 0 3px 5px rgba(0,0,0,.125)}
.btn-google{color:#fff;background-color:#111;border-color:#000;padding:15px 16px 5px 40px;position:relative;font-family:fontfutura;font-weight:600}
.btn-google:focus{color:#fff;background-color:#555;border-color:#000}
.btn-google:active,.btn-google:hover{color:#fff;background-color:#555;border-color:#000;}
.btn-google:before{content:"";background-image:url(https://4.bp.blogspot.com/-52U3eP2JDM4/WSkIT1vbUxI/AAAAAAAArQA/iF1BeARv2To-2FGQU7V6UbNPivuv_lccACLcB/s30/nexus2cee_ic_launcher_play_store_new-1.png);background-size:cover;background-repeat:no-repeat;width:30px;height:30px;position:absolute;left:6px;top:50%;margin-top:-15px}
.btn-google:after{content:"GET IT ON";position:absolute;top:5px;left:40px;font-size:10px;font-weight:400;}
  
.Dots{
    position: absolute;
    left: -30px;
    bottom: 0;
}
.dots-bg{
    position: relative;
    padding-bottom: 30px;
}
.home-about-main{
    margin-right: 15px;
    position: relative;
    -webkit-box-shadow: 0px 13px 46px 0px rgb(113 113 113 / 33%);
    -moz-box-shadow: 0px 13px 46px 0px rgba(113, 113, 113, 0.33);
    box-shadow: 0px 13px 46px 0px rgb(113 113 113 / 33%);
    border-radius: 10px;
    overflow: hidden;
}
.app-header-button {
    position: absolute;
    bottom: 65px;
    right: 43px;
}
.app-header-button img.appbtn {
    width: 279px;
}
.app-header-button a.btn {
    padding: 0;
    height: 80px;
    width: 290px;
}
img.main-appbtn {
    width: 250px;
}
@media only screen and (max-width: 768px) {
    .app-header-button img.appbtn {
        width: 124px;
    }
    .app-header-button {
        position: absolute;
        bottom: 26px;
        right: 16px;
    }
    .app-header-button a.btn {
        padding: 0;
        width: 140px;
        height: 45px;
    }
    .app-home p.main-app-headig {
        font-size: 25px;
    }
    .app-home p.red-text {
        font-size: 20px;
    }
    .app-home p.blue-stander-text {
        font-size: 44px;
    }
    .app-home p.black-dw-app-label {
        font-size: 35px;
    }
    .app-home span.Free-text {
        margin-left: 0;
    }
}







/*  */
body{
  background-color: #f1f6ff;
}
#news-slider{
    margin-top: 80px;
}
.post-slide{
    background: #fff;
    margin: 20px 15px 20px;
    border-radius: 15px;
    padding-top: 1px;
    box-shadow: 0px 14px 22px -9px #bbcbd8;
}
.post-slide .post-img{
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: -12px 15px 8px 15px;
    margin-left: -10px;
}
.post-slide .post-img img{
    width: 100%;
    height: auto;
    transform: scale(1,1);
    transition:transform 0.2s linear;
}
.post-slide:hover .post-img img{
    transform: scale(1.1,1.1);
}
.post-slide .over-layer{
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    opacity:0;
    background: linear-gradient(-45deg, rgba(6,190,244,0.75) 0%, rgba(45,112,253,0.6) 100%);
    transition:all 0.50s linear;
}
.post-slide:hover .over-layer{
    opacity:1;
    text-decoration:none;
}
.post-slide .over-layer i{
    position: relative;
    top:45%;
    text-align:center;
    display: block;
    color:#fff;
    font-size:25px;
}
.post-slide .post-content{
    background:#fff;
    padding: 2px 20px 40px;
    border-radius: 15px;
}
.post-slide .post-title a{
    font-size:15px;
    font-weight:bold;
    color:#333;
    display: inline-block;
    text-transform:uppercase;
    transition: all 0.3s ease 0s;
}
.post-slide .post-title a:hover{
    text-decoration: none;
    color:#3498db;
}
.post-slide .post-description{
    line-height:24px;
    color:#808080;
    margin-bottom:25px;
}
.post-slide .post-date{
    color:#a9a9a9;
    font-size: 14px;
}
.post-slide .post-date i{
    font-size:20px;
    margin-right:8px;
    color: #CFDACE;
}
.post-slide .read-more{
    padding: 7px 20px;
    float: right;
    font-size: 12px;
    background: #2196F3;
    color: #ffffff;
    box-shadow: 0px 10px 20px -10px #1376c5;
    border-radius: 25px;
    text-transform: uppercase;
}
.post-slide .read-more:hover{
    background: #3498db;
    text-decoration:none;
    color:#fff;
}
.owl-controls .owl-buttons{
    text-align:center;
    margin-top:20px;
}
.owl-controls .owl-buttons .owl-prev{
    background: #fff;
    position: absolute;
    top:-13%;
    left:15px;
    padding: 0 18px 0 15px;
    border-radius: 50px;
    box-shadow: 3px 14px 25px -10px #92b4d0;
    transition: background 0.5s ease 0s;
}
.owl-controls .owl-buttons .owl-next{
    background: #fff;
    position: absolute;
    top:-13%;
    right: 15px;
    padding: 0 15px 0 18px;
    border-radius: 50px;
    box-shadow: -3px 14px 25px -10px #92b4d0;
    transition: background 0.5s ease 0s;
}
.owl-controls .owl-buttons .owl-prev:after,
.owl-controls .owl-buttons .owl-next:after{
    content:"\f104";
    font-family: FontAwesome;
    color: #333;
    font-size:30px;
}
.owl-controls .owl-buttons .owl-next:after{
    content:"\f105";
}
img.CBSE.aApproved {
    width: 180px;
}
img.Model1.horizontal-logo {
    max-height: 500px;
}
@media only screen and (max-width:1280px) {
    .post-slide .post-content{
        padding: 0px 15px 25px 15px;
    }
}

button.btn.blue-button.w-100.font-weight-bold.mt-2 {
    white-space: break-spaces;
    width: auto;
    height: 55px;
    font-size: small;
}

.buynow-home-product{
    display: flex;
    justify-content: space-between;
    align-items: self-end;
}


