.Gallery .container {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); */
    justify-items: center;
    grid-gap: 0; }

.item {
    width: 100%;
    overflow: hidden;
    background: #000;
}

.Gallery img{
    /* opacity: 0.6; */
    width: 100%;
    height: 300px;
    transform: scale(1.15);
    transition: transform 0.5s, opacity 0.5s;
}

.Gallery img:hover {
    opacity: 1;
    transform: scale(1.03);
}

.item {
    display: inline-block;
    height: auto;
    overflow: hidden;
    text-align: center;
    width: 400px;
    margin: 10px;
}
.item img {
    width: 100%;
    height: auto;
    /* filter: url(filters.svg#grayscale); */
    filter: gray;
    /* -webkit-filter: grayscale(1); */
    transition: 0.9s;
}
.item img:hover {
    filter: none;
}

.isotope-pager .pager {
    display: inline-block;
}
.filters {
    width: 100%;
    text-align: center;
    padding-bottom: 35px;
    padding-top: 20px;
}

.filters ul {
    list-style: none;
    padding: 20px 10px;
    box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.4);
    margin: 0 40px;
    border-radius: 30px;
    background-color: #232323;
}

.filters li {
    display: inline-block;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}
.filters li a {
    color: #b6b6b6;
    text-decoration: none;
}
.filters li a:hover {
    color: #fff;
}

.filters li:hover {
    box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

.filters li.active a {
    color: #e3ca66;
    border: 1px solid #e3ca66;
    border-radius: 15px;
    box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.4);
    padding: 10px 25px;
}

.isotope-pager a {
    margin: 6px 1px;
    display: inline-block;
    width: 50px;
    /* height: 60px; */
    line-height: 31px;
    color: #b6b6b6;
    font-weight: 600;
    transition: 0.3s;
    /* position: relative; */
    /* padding-right: 26px; */
    box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    background-color: #232323;
    border: 0.5px solid #e3ca66;
    text-decoration: none;
}

.isotope-pager a:hover {
    color: #fff;
    border: 0.5px solid #ffeeac;
    box-shadow: -1px 1px 10px 3px rgba(0, 0, 0, 0.1);
}

.isotope-pager .active {
    color: #e3ca66 !important;
    box-shadow: -1px 1px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    background-color: #161616;
    border: 1px dotted #e3ca66;
}

.isotope-pager .prev {
    border-radius: 30px 0 0 30px;
}

.isotope-pager .next {
    border-radius: 0 30px 30px 0;
}

.isotope-pager a::before,
.isotope-pager a:after {
    content: "";
    position: absolute;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
}
.isotope-pager .num::before,
.next::before {
    border-right: 20px solid #2c3e50;
    right: 100%;
}

.isotope-pager .num:after,
.prev:after {
    border-right: 20px solid #f1f1f1;
    right: 0;
}

.overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
}

.item:hover .overlay {
    opacity: 1;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
    height: 100%;
}

body{
    font-family: sans-serif;
    font-size: 18px;
    background: #222;
}

ul{
    list-style: none;
}

img{
    max-width: 100%;
    height: auto;
}

.container{
    max-width: 1200px;
    margin: auto;
    padding: 15px;
    text-align: center;
}

h2{
    text-transform: uppercase;
    font-weight: normal;
    font-size: 120%;
    margin-bottom: 20px;
    color: #fff;
}

.filter-menu{
    margin-bottom: 20px;
}
.filter-menu li{
    display: inline-block;
    padding: 7px 16px;
    color: #00bbf2;
    background: white;
    border: 1px solid lightgray;
    cursor: pointer;
    margin: 5px;
    border-radius: 50px;
}
.filter-menu li:hover,
.filter-menu li.current{
    background: rgba(0, 187, 242, 0.9) none repeat scroll 0 0;
    color: #fff;
    border-radius: 50px;
    border: none;
}



.filter-item div{
    width: 44%;
    padding: 2px;
    float: left;
}

.filter-item div.active{
    width: 50%;
    padding: 2px;
    transition: all 0.5s ease;
    display: block;
}

.filter-item div.delete{
    width: 0%;
    padding: 0;
    transition: all 0.5s ease;
    display: none;
}
.Achievements .filter-item div.delete{
    width: 0%;
    padding: 0;
    transition: all 0.5s ease;
    display: inline-block;
}

.filter-item img{
    display: block;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 768px){
    .filter-item div.active,
    .filter-item div{
        width: 33.33%;
    }

    h2{
        font-size: 190%;
    }
}
@media screen and (max-width: 768px){
    .filter-item div.active {
        width: 44%;
    }
    .Gallery ul {
        padding-left: 0;
    }
}
@media screen and (min-width: 1280px){
    .filter-item div.active,
    .filter-item div{
        width: 31%;
    }

    h2{
        font-size: 270%;
    }
}


