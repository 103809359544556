/*
    DEMO STYLE
*/
/* 
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
} */

.dashboard line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper.dashboard{
    display: flex;
    width: 100%;
    align-items: stretch;
}

.dashboard #sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #00bbf2;
    color: #fff;
    transition: all 0.3s;
}

.dashboard #sidebar.active {
    margin-left: -250px;
}

.dashboard #sidebar .sidebar-header {
    padding: 20px;
    background: #00bbf2;
}

.dashboard #sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

.dashboard #sidebar ul p {
    color: #fff;
    padding: 10px;
}

.dashboard #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

.dashboard #sidebar ul li a:hover {
    color: #00bbf2;
    background: #fff;
}

.dashboard #sidebar ul li.active > a,
.dashboard a[aria-expanded="true"] {
    color: #fff;
    background: #00bbf2;
}

.dashboard a[data-toggle="collapse"] {
    position: relative;
}

.dashboard .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.dashboard ul ul a {
    font-size: 0.9em ;
    padding-left: 30px ;
    background: #00bbf2;
}

.dashboard ul.CTAs {
    padding: 20px;
}

.dashboard ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

.dashboard a.download {
    background: #fff;
    color: #00bbf2;
}

.dashboard a.article,
.dashboard a.article:hover {
    background: #00bbf2 !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.dashboard #content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    .dashboard #sidebar {
        margin-left: -250px;
    }
    .dashboard #sidebar.active {
        margin-left: 0;
    }
    .dashboard #sidebarCollapse span {
        display: none;
    }
}
