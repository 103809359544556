
 .timeline h1 {
    text-align: center;
    font-weight: 300;
    color: #777
 }
 
 .timeline h1 span {
    font-weight: 600;
 }
 
 .container1 {
    width: 90%;
    /* padding: 50px 0; */
    margin: 50px auto;
    position: relative;
    overflow: hidden;
 }
 
 .container1:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 100%;
    background: #CCD1D9;
    z-index: 1
 }
 
 .timeline h4.p-3 {
    width: 51%;
    text-align: center;
    border-radius: 10%;
}
 .timeline-block {
    width: -webkit-calc(50% + 61px);
    width: -moz-calc(50% + 61px);
    width: calc(50% + 61px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    /* -webkit-justify-content: space-around; */
    -moz-box-pack: justify;
    /* justify-content: space-around; */
    clear: both;
 }
 
 .timeline-block-right {
    float: right;
 }
 
 .timeline-block-left {
    float: left;
    direction: rtl
 }
 
 .marker {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #F5F7FA;
    background: #4FC1E9;
    margin-top: 10px;
    z-index: 9999
 }
 
 .timeline-content {
    width: 90%;
    padding: 0 15px;
    color: #666
 }
 
 .timeline-content h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 500
 }
 
 .timeline-content span {
    font-size: 15px;
    color: #a4a4a4;
 }
 
 .timeline-content p {
    font-size: 14px;
    line-height: 1.5em;
    word-spacing: 1px;
    color: #888;
 }
 
 
 @media screen and (max-width: 768px) {
    .container1:before {
       left: 52px;
       width: 2px;
    }
    .timeline-block {
       width: 100%;
       margin-bottom: 30px;
    }
    .timeline-block-right {
       float: none;
    }
 
    .timeline-block-left {
       float: none;
       direction: ltr;
    }
    .timeline-content {
        width: 89%;
        padding: 0 15px;
        color: #666;
    }
    .timeline h4.p-3 {
      width: 80%;
      text-align: center;
      border-radius: 10%;
  }
 }
 