footer .footer-top {
  background-image: url(../../../images/common/footerbg1.jpg);
  color: white;
  background-position: center;
  background-size: cover;
  padding: 10px 0;
  background-position: center center;
  background-attachment: fixed;
}

footer .inner {
  min-height: 200px;
  position: relative;
}

footer .footer-bottom {
  color: #00bbf2;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
}

@media only screen and (max-width: 768px) {
  footer .copyright {
    font-size: 14px;
    margin-right: 0 !important;
  }

  .footer-logo .logo {
    margin: 15px auto;
  }
}

.footer-logo {
  text-align: center;
}

.footer-logo .logo {
  width: 140px;
}

.social-link {
  display: flex;
  flex-wrap: wrap;
}

.social-link a {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  margin-right: 30px;
  margin-bottom: 10px;
}

.social-link i::before {
  color: #00bbf2;
}

.social-link a:hover {
  transform: scale(1.1);
  box-shadow: blue;
}

.footer-top a,
.footer-top p {
  color: #fff;
}

/*  */

.back-to-top {
  position: fixed;
  bottom: 1px;
  right: 25px;
  display: none;
}

.fa,
.fas {
  font-weight: 900;
}
.policy-group{
  display: flex;
  justify-content: flex-start;
}

.terms-policy{
  margin: auto 5px;
}